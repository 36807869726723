import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import { StaticImage } from "gatsby-plugin-image";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));
const Article = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "casestudy/f1city.jpg" }) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Event transport scaled for the world stage"}
      banner={
        <StaticImage
          src="../../assets/images/casestudy/f1city.jpg"
          alt="yas island ferrari world race track"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Abu Dhabi Formula One" }]}
      metaTitle="Abu Dhabi Formula 1 Grand Prix Case Study"
      metaDescription="Click to read how MOTUS | ONE provided Abu Dhabi Formula 1 with one of the region’s most successful transport solutions for the Formula 1 Grand Prix."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography customVariant={"h2Bold"} component="h1">
            Abu Dhabi Formula One® Case Study
          </Typography>

          <Typography customVariant={"h5Regular"}>
            Our original remit was to plan and implement transport for
            attendees, but our role grew and now includes an array of retained
            services.
          </Typography>
        </div>

        <Summary
          title={"Overview"}
          isFlipped={false}
          // eslint-disable-next-line react/jsx-no-undef
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Overview_Image.jpg"
              alt="Shuttle Pickup At Yas Marina Circuit"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              In September 2009, the Abu Dhabi Department of Transportation
              contracted MOTUS | ONE team members as a transport partner for the
              Etihad Airways Formula OneTM Grand Prix at the Yas Marina circuit.
              We implemented end-to-end solutions for attendee transport and
              were retained year-round through 2015 to create and manage
              day-to-day needs at the circuit, including the annual Grand Prix.
            </>
          }
        />

        <Summary
          title={"Our engagement"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Elements_Image.jpg"
              alt="Vehicle Parking Lot"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <ul>
              <li>Park-and-ride transportation</li>
              <li>Island-wide shuttle services</li>
              <li>VIP and Paddock Club transport</li>
              <li>ACTUAE marshals transport</li>
              <li>Car park management</li>
              <li>Arrivals and departure systems</li>
              <li>Wayfinding and signage</li>
              <li>Technology services and GPS tracking</li>
              <li>Crowd-management modeling</li>
              <li>Emergency evacuation process</li>
            </ul>
          }
        />

        <Summary
          title={"Outcomes"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Outcomes_Image.jpg"
              alt="Bus Stopping At Yas Marina Circuit"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Our team brought radical new efficiencies to transport systems and
              stakeholder engagement. We coordinated closely with Police, Civil
              Defense, and the Event Security Committee to deliver one of the
              region’s most successful
              <a href="https://motusone.com/transport/">transport solutions</a>.
              That success is reflected in the recognition we have gained from
              the Etihad Airways Abu Dhabi Formula OneTM Grand Prix organizers.
            </>
          }
        />

        <Summary
          title={"Legacy"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Legacy_Image.jpg"
              alt="Red Bull Formula One Racing Car"
              draggable="false"
            />
          }
          link={undefined}
          description={
            <>
              Over the years, MOTUS | ONE has established a relationship as a
              trusted partner with the Abu Dhabi Department of Transportation,
              the Abu Dhabi Executive Authority, and the Yas Marina Circuit
              management team. We continue to deliver innovative, reliable, and
              effective transport solutions for mega sporting events in and
              around the GCC.
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
